<template>
    <div class="help">
        <div class="head">
            <img :src="require('@/assets/img/help-head.png')" />

            <p class="welcome">你好！欢迎来到帮助中心！</p>
        </div>

        <p class="title">帮助教程</p>

        <div class="bottom"></div>

        <div class="details">
            <div class="details-top">
                <div @click="router.push('/Shimingrenzheng')">
                    <van-icon class="details-icon" name="shopping-cart-o" size="40" />
                    <p>实名认证</p>
                </div>
                <div @click="router.push('/Tianjiashoufukuan')">
                    <van-icon class="details-icon" name="shopping-cart-o" size="40" />
                    <p>添加收付款</p>
                </div>
                <div @click="router.push('/Maib')">
                    <van-icon class="details-icon" name="shopping-cart-o" size="40" />
                    <p>买币</p>
                </div>
            </div>

            <div class="details-bottom">
                <div @click="router.push('/Maibi')">
                    <van-icon class="details-icon" name="shopping-cart-o" size="40" />
                    <p>卖币</p>
                </div>
                <div @click="router.push('/Chongzhi')">
                    <van-icon class="details-icon" name="shopping-cart-o" size="40" />
                    <p>充值</p>
                </div>
                <div @click="router.push('/Tixian')">
                    <van-icon class="details-icon" name="shopping-cart-o" size="40" />
                    <p>提现</p>
                </div>
            </div>
        </div>

        <div class="btn">
            <van-button type="primary" block round size="normal" @click="router.push('/Service')">客服</van-button>
        </div>
    </div>
</template>

<script>
import { nextTick, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
    setup() {
        const router = useRouter()

        nextTick(() => {
            document.removeEventListener('touchmove', window.touchmoveListener, { passive: false })
        })
        onUnmounted(() => {
            document.addEventListener('touchmove', window.touchmoveListener, { passive: false })
        })

        return {
            router
        }
    }
}
</script>

<style lang="scss" scoped>
.help {
    .head {
        height: h(190);
        position: relative;
        background: #3366ee;
        // background-image: url('~@/assets/img/help-bg.png');
        background-image: url('~@/assets/img/help-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        img {
            position: absolute;
            width: w(120);
            height: w(120);
            bottom: h(-20);
            left: w(20);
        }

        .welcome {
            position: absolute;
            right: w(15);
            bottom: h(25);
            width: w(210);
            height: h(40);
            background: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            color: #ffffff;
            font-size: 14px;
            line-height: h(40);
            text-align: center;
        }
    }

    .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        color: #333333;
        margin: h(30) 0 h(15) w(15);
    }

    .bottom {
        width: w(302);
        height: h(1);
        background: #e0e0e0;
        margin: 0 auto;
    }

    .details {
        .details-top,
        .details-bottom {
            box-sizing: border-box;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            text-align: center;
            margin-top: h(20);

            div {
                flex: 1;

                p {
                    margin-top: h(12);
                    font-size: 12px;
                    line-height: 16px;
                    color: #4f4f4f;
                }
            }
        }
    }

    .btn {
        margin-top: h(72);
        padding: 0 w(30);
    }
}
</style>
